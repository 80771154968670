import { Result } from "./result"
import { FAQ, Testimonial } from "@interfaces/misc"
import HomepageRepositories from "../repositories/homepage.repositories"

export default class HomepageServices extends HomepageRepositories {
  async getFaqsList() {
    try {
      const res = await this.getRawFaqs()
      const response = await this._getResponse<FAQ[]>(res)

      const { error, result } = response

      if (error) {
        return Result.fail<FAQ[]>()
      }

      return Result.ok<FAQ[]>(result)
    } catch (e: any) {
      console.log({ e })
      return Result.fail<FAQ[]>()
    }
  }

  async getTestimonialsList() {
    try {
      const res = await this.getRawTestimonials()
      const response = await this._getResponse<Testimonial[]>(res)

      const { error, result } = response

      if (error) {
        return Result.fail<Testimonial[]>()
      }

      return Result.ok<Testimonial[]>(result)
    } catch (e: any) {
      return Result.fail<Testimonial[]>()
    }
  }
}

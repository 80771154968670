import { useEffect, useState } from "react"
import clsx from "clsx"

import { LinkButton, RegularButton } from "@components/button"
import { TextInput } from "@components/input"
import { ManualSelect } from "@components/select/ManualSelect"
import { container, FlexBox } from "@components/wrapper"
import { SelectOption } from "@interfaces/misc"
import CategoryServices from "@lib/api/services/category.services"
import { useRouter } from "next/router"

const jobTypes: SelectOption[] = [
  {
    value: "full-time",
    label: "Full Time",
  },
  {
    value: "part-time",
    label: "Part Time",
  },
  {
    value: "internship",
    label: "Internship",
  },
]

export const HomepageSearch = () => {
  const [position, setPosition] = useState("")
  const [jobType, setJobType] = useState("")
  const [selectedTeam, setSelectedTeam] = useState("")
  const [team, setTeam] = useState<SelectOption[]>()

  const router = useRouter()
  const category = new CategoryServices()

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await category.getCategoryList()

        if (res.isSuccess) {
          const result = res.getValue()
          const tempArr: SelectOption[] = []
          result.map((element) => {
            tempArr.push({
              value: element.category_slug,
              label: element.category_name,
            })
          })
          setTeam(tempArr)
        }
      } catch (e: any) {
        console.log(e)
      }
    }

    getData()
  }, [])

  const handleSearch = async () => {
    let positionQuery = ""

    if (position) {
      positionQuery = position.toLowerCase()
    }

    return await router.push({
      pathname: `/jobs${selectedTeam && `/${selectedTeam}`}`,
      query:
        !position && !selectedTeam
          ? {}
          : {
              ...(jobType && { type: jobType }),
              ...(positionQuery && { search: positionQuery }),
            },
    })
  }

  return (
    <section className={container}>
      <FlexBox
        className={clsx(
          "sm:flex-row bg-primary-grey rounded sm:px-7 py-8 sm:gap-6",
          "px-5 flex-col gap-5"
        )}
      >
        <TextInput
          placeholder="Cari posisi pekerjaan"
          className="sm:w-2/6 w-full"
          customPadding="py-3 px-5"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
        />

        <FlexBox direction="row" className="sm:w-2/6 w-full sm:gap-6 gap-4">
          <ManualSelect
            className="w-1/2"
            placeholder="Tim"
            controlClassname="!border-2 !border-green"
            options={team}
            onChange={(e: any) => setSelectedTeam(e.value)}
          />
          <ManualSelect
            className="w-1/2"
            placeholder="Tipe Kerja"
            controlClassname="!border-2 !border-green"
            options={jobTypes}
            onChange={(e: any) => setJobType(e.value)}
          />
        </FlexBox>
        <RegularButton className="sm:w-1/6 w-full" customPadding="py-3" onClick={handleSearch}>
          Cari
        </RegularButton>
        <LinkButton
          className="sm:w-1/6 w-full"
          href="/jobs"
          variant="transparent-white"
          customPadding="py-3"
        >
          Lihat Semua
        </LinkButton>
      </FlexBox>
    </section>
  )
}

import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { Accordion, AccordionHead, AccordionItem, AccordionItemPanel } from "@harisenin/component"
import { FAQ } from "@interfaces/misc"
// import MiscServices from "@services/misc.services"
import { flex_center } from "@constants/styles"
import HomepageServices from "@services/homepage.services"

export function HomepageFaq() {
  const [isLoading, setIsLoading] = useState(true)
  const [faqs, setFaqs] = useState<FAQ[]>([])

  // const misc = new MiscServices()
  const homepage = new HomepageServices()

  useEffect(() => {
    const getData = async () => {
      try {
        // const res = await misc.getFaqs()
        const res = await homepage.getFaqsList()

        if (res.isSuccess) {
          const result = res.getValue()
          setFaqs(result)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }

    getData()
  }, [])

  return (
    <section className="pt-10 pb-20">
      <h2
        className={clsx(
          "font-semibold text-center sm:text-2.5xl sm:leading-10 text-secondary-black sm:mb-8",
          "text-md leading-6 mb-4"
        )}
      >
        Frequently Asked Question (FAQ)
      </h2>

      <div className={clsx("sm:w-3/5 sm:mx-auto sm:px-0", "px-4 w-full")}>
        <Accordion>
          {faqs.map((value, index) => (
            <AccordionItem key={index} className="mb-4">
              <AccordionHead
                chevronDirection="down"
                buttonClassName={clsx(flex_center, "text-white")}
                textClassName="font-semibold text-base-responsive text-white"
                className={clsx(
                  "sm:py-4 sm:px-7 sm:rounded-t-10 align-center bg-green",
                  "rounded py-3 px-6"
                )}
              >
                {value.faq_question}
              </AccordionHead>

              <AccordionItemPanel
                className={clsx("sm:px-7 py-5 bg-primary-grey rounded-b-10", "px-6")}
              >
                <div className="text-sm text-green">{value.faq_answer}</div>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  )
}

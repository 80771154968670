import React from "react"
import { container, FlexBox } from "@components/wrapper"
import clsx from "clsx"
import Image from "next/image"
import { HARISENIN_PUBLIC_ICON } from "@const/pictures"

interface Benefit {
  title: string
  icon: string
}

const BENEFIT: Benefit[] = [
  {
    icon: "icon_career-1.png",
    title: "Flexible Work Arrangement (Remote Working)",
  },
  {
    icon: "icon_career-2.png",
    title: "Skill Upgrade and Personal Development",
  },
  {
    icon: "icon_webapp-2.png",
    title: "Professional Connection",
  },
  {
    icon: "icon_webapp-4.png",
    title: "Competitive Salary and Additional Reward",
  },
]

export function CareerBenefit() {
  return (
    <section className={clsx(container, "pt-5 sm:pb-20 pb-2.5")}>
      <div className="text-center sm:mb-60px mb-10">
        <h2
          className={clsx(
            "font-semibold text-center sm:text-2.5xl sm:leading-10 text-secondary-black mb-3",
            "text-md leading-6"
          )}
        >
          Benefit Karier di <span className="text-green">Harisenin.com</span>
        </h2>
        <div>
          <span className="font-semibold">Valuable Benefit</span> yang tentunya bisa kamu dapatkan
          di Harisenin.com
        </div>
      </div>

      <FlexBox className={clsx("sm:gap-9 sm:flex-nowrap", "flex-wrap")}>
        {BENEFIT.map((item, i) => (
          <div key={i} className={clsx("sm:w-1/4 sm:mb-0", "w-1/2 mb-10")}>
            <div className="wh-10 mb-4">
              <Image src={`${HARISENIN_PUBLIC_ICON}/${item.icon}`} width={60} height={60} alt="career benefit" />
            </div>
            <div
              className={clsx(
                "sm:w-3/4 sm:font-normal text-base-responsive",
                "w-[90%] font-medium"
              )}
            >
              {item.title}
            </div>
          </div>
        ))}
      </FlexBox>
    </section>
  )
}

import React from "react"
import Image from "next/image"
import clsx from "clsx"

import { container, FlexBox } from "@components/wrapper"
import { HARISENIN_PUBLIC_PAGE_ASSETS } from "@const/pictures"
import { LinkButton, RegularButton } from "@components/button"

interface HomepageBannerProps {
  scroll: (id: any) => () => void
}

export const HomepageBanner: React.FC<HomepageBannerProps> = ({ scroll }) => {
  return (
    <section className={container}>
      <FlexBox
        className={clsx("sm:pt-16 w-full sm:justify-between sm:flex-row", "flex-col gap-10")}
      >
        <FlexBox direction="col" className={clsx("max-w-lg sm:pt-16", "pt-6")}>
          <h1
            className={clsx(
              "font-semibold sm:text-3xl sm:leading-[2.8rem] text-dark-green fade_in",
              "text-2xl leading-9 mb-6"
            )}
          >
            Buka <span className="text-green">Peluang</span> Baru <br />
            dengan <span className="text-blue">Pengalaman</span> Baru
          </h1>

          <div
            className={clsx(
              "sm:font-medium text-grey-97 sm:leading-6 sm:mb-6",
              "font-semibold leading-5 mb-10"
            )}
          >
            Temukan peluang yang paling tepat untuk menjadikanmu berkembang bersama kami.
          </div>

          <FlexBox className={clsx("sm:gap-7 w-full", "gap-4")}>
            <RegularButton
              className="sm:w-2/5 w-1/2"
              customPadding="py-2.5"
              onClick={scroll("category-column")}
            >
              Lihat Peluang
            </RegularButton>
            <LinkButton
              href="/form/pool"
              className="sm:w-2/5  w-1/2 !border-2"
              variant="transparent"
              customPadding="py-2.5"
            >
              Talent Pool
            </LinkButton>
          </FlexBox>
        </FlexBox>

        <FlexBox className="sm:pr-6 px-8">
          <Image
            src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/career_banner.png`}
            alt="career banner"
            width={530}
            height={500}
          />
        </FlexBox>
      </FlexBox>
    </section>
  )
}

import KyMethod from "./KyMethod"

export default class HomepageRepositories extends KyMethod {
  protected async getRawFaqs() {
    return this._getData("/career/faqs")
  }

  protected async getRawTestimonials() {
    return this._getData("/career/testimonials")
  }
}

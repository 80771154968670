import React, { useEffect, useState } from "react"
import { ScreenType } from "@lib/hooks"
import clsx from "clsx"
import { CareerCategory } from "@interfaces/career"
import { Container, FlexBox } from "@components/wrapper"
import { BasicLink } from "@components/typography"
// import { HARISENIN_PUBLIC_ICON } from "@const/pictures"
import CategoryServices from "@lib/api/services/category.services"
import Image from "next/image"

export function CategoryColumn({ deviceType }: { deviceType: ScreenType }) {
  const [categories, setCategories] = useState<CareerCategory[]>([])
  const [isHover, setIsHover] = useState(-1)

  const category = new CategoryServices()

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await category.getCategoryList()

        if (res.isSuccess) {
          const result = res.getValue()
          setCategories(result)
        }
      } catch (e: any) {
        console.log(e)
      }
    }

    getData()
  }, [])

  return (
    <section className={clsx("bg-primary-grey sm:pt-10 sm:pb-60px relative", "py-7")}>
      <div id="category-column" className="absolute -top-[80px]" />
      <h2
        className={clsx(
          "font-semibold text-center sm:text-2.5xl sm:leading-10 text-secondary-black sm:mb-10",
          "text-md leading-6 mb-5"
        )}
      >
        Temukan Peluang Kariermu
      </h2>

      {deviceType !== "mobile" && (
        <Container className="grid grid-cols-3 gap-8">
          {categories.map((category, i) => (
            <BasicLink
              key={i}
              href={`/jobs/${category.category_slug}`}
              className="bg-white h-[18vw] px-60px flex flex-col justify-center hover:shadow-[0px_6.6px_5.3px_0px_rgba(0,0,0,0.0283)] hover:text-white hover:bg-green"
              onMouseEnter={() => setIsHover(i)}
              onMouseLeave={() => setIsHover(-1)}
            >
              <div>
                <Image
                  src={
                    i === isHover
                      ? category.asset_hover.asset_path
                      : category.asset_original.asset_path
                  }
                  alt={category.category_name}
                  className="h-8 w-auto mb-8 text-green"
                  width={200}
                  height={64}
                />
                <div className="font-semibold text-xl">{category.category_name}</div>
              </div>
            </BasicLink>
          ))}
        </Container>
      )}

      {deviceType === "mobile" && (
        <div className="slider-mobile pl-4 w-full pb-1 !mb-0">
          <FlexBox className="w-max gap-5">
            {categories.map((category, i) => (
              <BasicLink
                key={i}
                href={`/jobs/${category.category_slug}`}
                className="bg-white h-[48vw] w-[75vw] px-9 flex flex-col justify-center hover:shadow-[0px_6.6px_5.3px_0px_rgba(0,0,0,0.0283)] hover:text-white hover:bg-green"
                onMouseEnter={() => setIsHover(i)}
                onMouseLeave={() => setIsHover(-1)}
              >
                <div>
                  <Image
                    src={
                      i === isHover
                        ? category.asset_hover.asset_path
                        : category.asset_original.asset_path
                    }
                    alt={category.category_name}
                    className="h-8 mb-8 text-green"
                    width={200}
                    height={64}
                  />
                  <div className="font-semibold text-medium">{category.category_name}</div>
                </div>
              </BasicLink>
            ))}
          </FlexBox>
        </div>
      )}
    </section>
  )
}

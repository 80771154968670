import React, { useEffect, useState } from "react"
import Carousel, { DotProps } from "react-multi-carousel"
import { ScreenType } from "@lib/hooks"
import { Container, FlexBox } from "@components/wrapper"
import { Testimonial } from "@interfaces/misc"
// import MiscServices from "@services/misc.services"
import { SliderArrow } from "@components/slider"
import clsx from "clsx"
import { Avatar } from "@components/misc"
import HomepageServices from "@services/homepage.services"

const TestimonialCard = ({ value }: { value: Testimonial }) => {
  return (
    <FlexBox
      direction="col"
      justify="between"
      className={clsx(
        "relative rounded-10 py-[18px] sm:px-[26px] sm:h-[17em] bg-white shadow-[0_2px_15px_0_#9aaacf33] sm:w-auto sm:mr-0",
        "w-[70vw] mr-[5vw] px-[18px]"
      )}
    >
      <div>
        <FlexBox align="center" className="mb-6 gap-2.5">
          <div className="rounded w-[60px] h-[60px]">
            <Avatar src={value.testimony_user_picture} alt="" size={60} />
          </div>
          <div className="text-sm">
            <div className="font-bold">{value.testimony_username}</div>
            <div className="text-[#909090]">{value.testimony_user_as}</div>
          </div>
        </FlexBox>

        <div
          className={clsx(
            "text-sm sm:leading-6 sm:h-[10.5em] sm:line-clamp-6 overflow-hidden",
            "h-full leading-[22px]"
          )}
        >
          {value.testimony}
        </div>
      </div>
    </FlexBox>
  )
}

// const CustomDot: React.FC<DotProps> = ({ active, onClick }) => {
//   return (
//     <button
//       className={clsx(
//         "wh-3 rounded-full mx-1.5 border-0 transition-all duration-200",
//         active ? "bg-green w-7 rounded-[20px]" : "bg-grey-c4 w-auto rounded-0"
//       )}
//       onClick={(e) => {
//         if (onClick) {
//           onClick()
//         }
//         e.preventDefault()
//       }}
//     />
//   )
// }

export function CareerTestimonial({ deviceType }: { deviceType: ScreenType }) {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([])

  // const misc = new MiscServices()
  const homepage = new HomepageServices()

  useEffect(() => {
    const getData = async () => {
      try {
        // const res = await misc.getTestimonials()
        const res = await homepage.getTestimonialsList()

        if (res.isSuccess) {
          const data = res.getValue()
          setTestimonials(data)
        }

        // setIsLoading(false)
      } catch (e: any) {
        // school.bugsnagNotifier(e)
        // setIsLoading(false)
      }
    }

    getData()
  }, [])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
  }

  if (!testimonials.length) {
    return <></>
  }
  return (
    <section className="sm:pt-5 sm:pb-60px pb-5">
      <Container>
        <h2
          className={clsx("sm:text-2.5xl font-semibold sm:mb-[60px] text-center", "text-md mb-5")}
        >
          Apa Kata Mereka Tentang Kami?
        </h2>
      </Container>

      {deviceType !== "mobile" && (
        <div className="relative w-[calc(90%-10px)] mx-auto">
          <Carousel
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            arrows={false}
            shouldResetAutoplay={false}
            customButtonGroup={
              <SliderArrow
                length={testimonials.length}
                displayedSlide={3}
                leftClassName="-left-[1vw] top-[42%] w-[60px] h-[60px]"
                rightClassName="-right-[1vw] top-[42%] w-[60px] h-[60px]"
              />
            }
            autoPlay={false}
            renderButtonGroupOutside
            itemClass="pr-5 pl-3"
            containerClass="pb-[50px]"
          >
            {testimonials.map((value, index) => (
              <TestimonialCard value={value} key={index} />
            ))}
          </Carousel>
        </div>
      )}

      {deviceType === "mobile" && (
        <div className="slider-mobile pl-4 w-full pb-4">
          <div className="flex w-max">
            {testimonials.map((value, index) => (
              <TestimonialCard value={value} key={index} />
            ))}
          </div>
        </div>
      )}
    </section>
  )
}

import React, { FC, useState } from "react"
import Image from "next/image"

import { container, FlexBox } from "@components/wrapper"
import { HARISENIN_PUBLIC_PAGE_ASSETS } from "@const/pictures"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import clsx from "clsx"
import { useScreenSize } from "@lib/hooks"
import { useInterval } from "@hooks/useInterval"
import { Transition } from "@headlessui/react"
import Carousel, { ButtonGroupProps } from "react-multi-carousel"

interface CareerSlide {
  id: number
  title: string
  description: string
  asset: string
}

const SLIDE_DATA: CareerSlide[] = [
  {
    id: 1,
    title: "Champion the Mission",
    description:
      "Bersama meningkatkan akses pendidikan yang berkualitas terlepas dari latar belakang sosial dan ekonomi, untuk mewujudkan masa depan yang lebih baik.",
    asset: "career_slide-1.png",
  },
  {
    id: 2,
    title: "Be Thoughtful",
    description:
      "Ekspansikan dirimu menjadi orang yang lebih peka dan peduli terhadap lingkungan sekitar.",
    asset: "career_slide-2.png",
  },
  {
    id: 3,
    title: "Be Different",
    description: "Naikkan tingkat inovasi dan kreativitasmu untuk menjadi yang terbaik.",
    asset: "career_slide-3.png",
  },
  {
    id: 4,
    title: "Grow Winning Mindset",
    description: "Idealisasikan pemikiran yang akan membawa kamu untuk tumbuh dan berkembang.",
    asset: "career_slide-4.png",
  },
  {
    id: 5,
    title: "Effective and Efficient",
    description: "Niatkan dirimu untuk bekerja keras untuk hal yang kamu banggakan.",
    asset: "career_slide-5.png",
  },
]

const responsive = {
  mobile: {
    breakpoint: { max: 567, min: 0 },
    items: 1,
  },
}

export function CoreValues() {
  const [shownIndex, setShownIndex] = useState(0)
  const [fade, setFade] = useState(false)
  const [fadeInterval, setFadeInterval] = useState(0)

  useInterval(() => {
    if (deviceType === "mobile") {
      return
    }

    if (fadeInterval === 19) {
      setFade(true)
      setFadeInterval(fadeInterval + 1)
    } else {
      setFade(false)

      if (fadeInterval >= 20) {
        setFadeInterval(0)

        if (shownIndex === SLIDE_DATA.length - 1) {
          setShownIndex(0)
        } else {
          setShownIndex(shownIndex + 1)
        }
      } else {
        setFadeInterval(fadeInterval + 1)
      }
    }
  }, 200)

  const { deviceType } = useScreenSize()

  const handleSliderButton = (type: "prev" | "next") => () => {
    if (type === "prev" && shownIndex > 0) {
      return setShownIndex(shownIndex - 1)
    }

    if (type === "next" && shownIndex < 4) {
      return setShownIndex(shownIndex + 1)
    }
  }

  return (
    <section className="sm:w-3/4 mx-auto sm:py-60px pt-10 relative">
      <div id="core-values" className="absolute -top-10" />
      <div className={clsx(deviceType === "mobile" && container)}>
        <h2 className="text-grey-97 font-semibold sm:mb-14px mb-2">Core Values</h2>
      </div>

      {deviceType !== "mobile" && (
        <>
          <FlexBox className="mb-4 h-[320px] gap-10" justify="between">
            <div className="w-3/4">
              <Transition
                appear
                show={!fade}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <h3 className="text-3xl font-bold mb-10">{SLIDE_DATA[shownIndex].title}</h3>

                <div className="mb-20">{SLIDE_DATA[shownIndex].description}</div>

                <FlexBox className="gap-[70px] text-2xl">
                  <button
                    onClick={handleSliderButton("prev")}
                    className={clsx(shownIndex === 0 && "text-grey-97 cursor-not-allowed")}
                    disabled={shownIndex === 0}
                  >
                    <FaChevronLeft />
                  </button>

                  <button
                    onClick={handleSliderButton("next")}
                    className={clsx(shownIndex === 4 && "text-grey-97 cursor-not-allowed")}
                    disabled={shownIndex === 4}
                  >
                    <FaChevronRight />
                  </button>
                </FlexBox>
              </Transition>
            </div>

            <div className="w-1/4">
              <Transition
                appear
                show={!fade}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Image
                  src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/${SLIDE_DATA[shownIndex].asset}`}
                  alt="core values harisenin.com"
                  width={320}
                  height={320}
                  placeholder="blur"
                  blurDataURL={`"https://singlecolorimage.com/get/979797/320x320}"`}
                />
              </Transition>
            </div>
          </FlexBox>

          <FlexBox justify="between">
            {SLIDE_DATA.map((data, index) => (
              <FlexBox key={index} className="gap-4" align="center">
                <FlexBox
                  className={clsx(
                    "text-sm wh-6 bg-grey-97 text-white font-bold rounded-full",
                    shownIndex === index && "!bg-green"
                  )}
                  justify="center"
                  align="center"
                >
                  {data.id}
                </FlexBox>

                <div
                  className={clsx(
                    "text-grey-97 font-semibold text-sm",
                    shownIndex === index && "!text-green"
                  )}
                >
                  {data.title}
                </div>
              </FlexBox>
            ))}
          </FlexBox>
        </>
      )}

      {deviceType === "mobile" && (
        <div className={clsx("relative pb-60px", container)}>
          <Carousel
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            arrows={false}
            autoPlaySpeed={5000}
            infinite
            autoPlay
            customButtonGroup={<SliderArrow length={SLIDE_DATA.length} displayedSlide={1} />}
          >
            {SLIDE_DATA.map((item, index) => (
              <div key={index}>
                <h3 className={clsx("text-2xl font-bold mb-4")}>{item.title}</h3>

                <div className="mb-7 text-sm h-[28vw]">{item.description}</div>

                <div className="w-3/5 mx-auto mb-6">
                  <Image
                    src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/${item.asset}`}
                    alt="core values harisenin.com"
                    width={320}
                    height={320}
                    placeholder="blur"
                    blurDataURL={`"https://singlecolorimage.com/get/979797/320x320}"`}
                  />
                </div>

                <FlexBox key={index} className="gap-2 mx-auto w-fit" align="center">
                  <FlexBox
                    className="text-xs w-18px h-18px bg-green text-white font-bold rounded-full"
                    justify="center"
                    align="center"
                  >
                    {item.id}
                  </FlexBox>

                  <div className="text-green font-semibold text-sm">{item.title}</div>
                </FlexBox>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </section>
  )
}

interface SliderArrowProps extends ButtonGroupProps {
  length: number
  displayedSlide: number
}

const SliderArrow: FC<SliderArrowProps> = ({
  next,
  previous,
  length,
  displayedSlide,
  ...props
}) => {
  const current = props.carouselState?.currentSlide

  return (
    <div className="absolute w-full">
      <FlexBox className="w-[90%] mx-auto bottom-0" justify="between">
        <button
          onClick={previous}
          className={clsx(
            "text-lg",
            (current === 0 || length < displayedSlide) && "text-grey-97 cursor-not-allowed"
          )}
          disabled={current === 0 || length < displayedSlide}
        >
          <FaChevronLeft />
        </button>
        <button
          onClick={next}
          className={clsx(
            "text-lg",
            (length - displayedSlide === current || length < displayedSlide) &&
              "text-grey-97 cursor-not-allowed"
          )}
          disabled={length - displayedSlide === current || length < displayedSlide}
        >
          <FaChevronRight />
        </button>
      </FlexBox>
    </div>
  )
}

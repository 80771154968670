import { ImageCover } from "@components/misc"
import { Container, FlexBox } from "@components/wrapper"
import { HARISENIN_PUBLIC_PAGE_ASSETS } from "@const/pictures"
import clsx from "clsx"

export const HomepageQuotes = () => {
  return (
    <section className="sm:py-60px">
      <h2
        className={clsx(
          "font-semibold text-center sm:text-2.5xl sm:leading-10 text-secondary-black  sm:mb-10",
          "text-md leading-6 mb-5"
        )}
      >
        Bersama, Kita Wujudkan <span className="text-green">Impian!</span>
      </h2>
      <FlexBox justify="center" className={clsx("w-full bg-green")}>
        <ImageCover
          src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/founder_1.png`}
          alt="image founders"
          size={[900, 370]}
          className="w-full max-w-[900px]"
        />
      </FlexBox>

      <Container>
        <FlexBox
          className={clsx("bg-primary-grey px-4 sm:pt-5 sm:pb-20 rounded-b-lg", "pt-3 pb-5")}
          justify="center"
        >
          <FlexBox direction="col" className="max-w-3xl">
            <div
              className={clsx(
                "font-semibold sm:text-2.5xl sm:leading-10 text-dark-green sm:mb-5 sm:text-left",
                "text-md leading-6 text-center mb-3"
              )}
            >
              “Setiap generasi muda berhak mewujudkan impian mereka”
            </div>
            <p className={clsx("sm-only:text-sm sm:leading-6", "leading-5")}>
              Kami mendirikan Harisenin.com karena kami percaya sarana pendidikan tinggi saat ini
              kurang dapat diandalkan. Para pelajar memutuskan untuk berkuliah dengan harapan untuk
              segera mendapatkan pekerjaan. Namun, tidak ada jaminan dari manapun bahwa mereka akan
              langsung siap untuk terjun ke dunia kerja. Berbagai solusi untuk memperdalam skill
              masih menjadi tantangan bagi mereka. Dengan hadirnya Harisenin.com, kami berharap
              dapat membantu proses pengembangan karier dalam waktu singkat hingga kamu dapat meraih
              masa depan impian.
            </p>
          </FlexBox>
        </FlexBox>
      </Container>
    </section>
  )
}

import React from "react"
import clsx from "clsx"
import { Container, FlexBox } from "@components/wrapper"
import { ScreenType } from "@lib/hooks"

const recruitmentSteps = [
  "Seleksi Berkas atau Administasi",
  "Human Resources Interview",
  "Case Study (posisi tertentu) / User Interview",
  "Final Interview & Offering Letter",
]

interface StepProps {
  index: number
  point: string
}

const Step: React.FC<StepProps> = ({ point, index }) => {
  return (
    <div
      className={clsx(
        "sm:w-1/4 bg-primary-grey sm:py-30px sm:px-5 font-semibold relative sm:justify-start sm:block rounded",
        "w-[55vw] py-5 flex items-center"
      )}
    >
      <div
        className={clsx(
          "absolute bg-green outline outline-8 outline-[#B6E6DD] wh-14px rounded-full lg:-top-[48px] left-[calc(50%-7px)]",
          "-top-[30px]"
        )}
      />
      <FlexBox className={clsx("sm:gap-14px sm:w-auto sm:mx-0", "mx-auto w-3/4")}>
        <div>{index + 1}.</div>
        <div className="sm:text-left text-center">{point}</div>
      </FlexBox>
    </div>
  )
}

export const RequirementStep = ({ deviceType }: { deviceType: ScreenType }) => {
  return (
    <section className="pt-10 pb-10">
      <h2
        className={clsx(
          "font-semibold text-center sm:text-2.5xl sm:leading-10 text-secondary-black sm:mb-60px",
          "text-md leading-6 mb-2"
        )}
      >
        Tahapan Rekruitmen
      </h2>

      {deviceType !== "mobile" && (
        <Container>
          <FlexBox className="gap-8 border-t-3 border-green pt-10">
            {recruitmentSteps.map((point, i) => (
              <Step key={i} index={i} point={point} />
            ))}
          </FlexBox>
        </Container>
      )}

      {deviceType === "mobile" && (
        <div className="slider-mobile w-full pl-4 pt-5">
          <FlexBox className="gap-3 border-t-3 border-green pt-5 w-max">
            {recruitmentSteps.map((point, i) => (
              <Step key={i} index={i} point={point} />
            ))}
          </FlexBox>
        </div>
      )}

      {/*<FlexBox className="sm:mt-12 slider-row mt-6">*/}
      {/*  {recruitmentSteps.map((item, index) => (*/}
      {/*    <FlexBox*/}
      {/*      key={index}*/}
      {/*      direction="col"*/}
      {/*      className={clsx("sm:gap-10 sm:w-[315px] flex-shrink-0 w-[220px] pt-3", "gap-6")}*/}
      {/*    >*/}
      {/*      <FlexBox align="center" direction="col">*/}
      {/*        <div className="sm:h-[3px] h-[2px] bg-green w-full" />*/}
      {/*        <div className="h-3 w-3 -mt-1.5 bg-green outline-[6px] outline outline-[#B6E6DD] rounded-full" />*/}
      {/*      </FlexBox>*/}
      {/*      <div*/}
      {/*        className={clsx(*/}
      {/*          "sm:p-6 bg-primary-grey flex-shrink-0",*/}
      {/*          "p-5",*/}
      {/*          index === 0*/}
      {/*            ? "sm:mr-3 mr-1"*/}
      {/*            : index === recruitmentSteps.length - 1*/}
      {/*            ? "sm:ml-3 ml-1"*/}
      {/*            : "sm:mx-3 mx-1"*/}
      {/*        )}*/}
      {/*      >*/}
      {/*        <FlexBox*/}
      {/*          className={clsx(*/}
      {/*            "gap-2 items-start font-semibold sm:text-md sm:leading-6",*/}
      {/*            "text-sm leading-4"*/}
      {/*          )}*/}
      {/*        >*/}
      {/*          <div>{index + 1}.</div>*/}
      {/*          <div>{item}</div>*/}
      {/*        </FlexBox>*/}
      {/*      </div>*/}
      {/*    </FlexBox>*/}
      {/*  ))}*/}
      {/*</FlexBox>*/}
    </section>
  )
}

import { Container, FlexBox } from "@components/wrapper"
import { ImageCover } from "@components/misc"
import { HARISENIN_PUBLIC_PAGE_ASSETS } from "@const/pictures"
import clsx from "clsx"
import { ScreenType, useScreenSize } from "@lib/hooks"

const reasons = [
  "Pengalaman dan peluang baru",
  "Cita-cita dan semangat kolektif",
  "Budaya kerja kekeluargaan",
]

export const JoinUs = ({ deviceType }: { deviceType: ScreenType }) => {
  return (
    <section className="sm:py-60px py-10">
      <Container>
        <FlexBox className={clsx("sm:flex-row sm:gap-[70px]", "flex-col")}>
          <div className={clsx("max-w-[290px] w-full sm:mx-0", "mx-auto")}>
            <ImageCover
              src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/career_why.png`}
              alt="image why join us"
              size={[290, 290]}
            />
          </div>

          <FlexBox direction="col" className="sm:pt-10 pt-4">
            <h2
              className={clsx(
                "font-semibold sm:text-2.5xl leading-[48px] text-secondary-black sm:mb-5",
                "text-2xl leading-8 mb-3"
              )}
            >
              Mulai Berkembang <br className="sm:hidden flex" /> Bersama kami
            </h2>

            {deviceType !== "mobile" ? (
              <>
                <div className="mb-4">Alasan mengapa kamu harus berkembang bersama kami :</div>
                <ul className="flex flex-col mt-4 gap-3 list-disc font-medium">
                  {reasons.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </>
            ) : (
              <p className="text-sm leading-5">
                Bersama kami, dapatkan pengalaman dan peluang baru yang akan membawamu ke banyak
                tempat. Bekerja dengan banyak orang yang memiliki cita-cita dan semangat yang sama,
                Harisenin.com melihat semua sebagai keluarga dan terikat padu dengan nilai dan
                budaya kerja yang sudah tertanam dengan kuat. Dengan kekuatan ini, kamu dapat
                membantu kami memberikan pelayanan terbaik bagi para pejuang masa depan.
              </p>
            )}
          </FlexBox>
        </FlexBox>
      </Container>
    </section>
  )
}

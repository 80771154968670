import React from "react"
import Select, {
  components,
  ContainerProps,
  ControlProps,
  DropdownIndicatorProps,
  InputProps,
  OptionProps,
  PlaceholderProps,
  Props,
  SingleValueProps,
} from "react-select"
import { SelectOption } from "@interfaces/misc"
import clsx from "clsx"
import { IoChevronDown } from "react-icons/io5"

export interface ManualSelectProps extends Props {
  error?: string
  defaultOption?: SelectOption
  containerClassname?: string
  controlClassname?: string
  optionClassname?: string
}

export const ManualSelect: React.FC<ManualSelectProps> = ({
  className,
  containerClassname,
  controlClassname,
  optionClassname,
  ...props
}) => {
  const SelectContainer: React.FC<ContainerProps> = ({ children, ...props }) => {
    return (
      <components.SelectContainer {...props} className={clsx(className)}>
        {children}
      </components.SelectContainer>
    )
  }

  const Control: React.FC<ControlProps> = ({ children, ...props }) => {
    return (
      <components.Control {...props} className={controlClassname}>
        {children}
      </components.Control>
    )
  }

  const IndicatorSeparator = () => {
    return <></>
  }

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({ ...props }) => {
    return (
      <components.DropdownIndicator {...props}>
        <IoChevronDown className="!text-secondary-black" />
      </components.DropdownIndicator>
    )
  }

  const Option: React.FC<OptionProps> = ({ children, ...props }) => {
    return (
      <components.Option {...props} className={optionClassname}>
        {children}
      </components.Option>
    )
  }

  const Input: React.FC<InputProps> = ({ children, ...props }) => {
    return <components.Input {...props}>{children}</components.Input>
  }

  const SingleValue: React.FC<SingleValueProps> = ({ children, ...props }) => {
    return <components.SingleValue {...props}>{children}</components.SingleValue>
  }

  const Placeholder: React.FC<PlaceholderProps> = ({ children, ...props }) => {
    return <components.Placeholder {...props}>{children}</components.Placeholder>
  }

  return (
    <Select
      {...props}
      components={{
        SelectContainer,
        Control,
        IndicatorSeparator,
        DropdownIndicator,
        Option,
        Input,
        SingleValue,
        Placeholder,
      }}
      styles={{
        control: (props) => ({
          ...props,
          border: "1px solid #231F20",
          borderColor: "#231F20",
          boxShadow: "0 0 0 #231F20",
          borderRadius: "5px",
          minHeight: 0,
          padding: "10px 18px",
          "&:hover": {
            border: "1px solid #231F20",
            boxShadow: "0 0 0 #231F20",
          },
          "&:focus": {
            border: "1px solid #231F20",
            boxShadow: "0 0 0 #231F20",
          },
          "@media(max-width: 576px)": {
            fontSize: "14px",
          },
        }),
        input: (props) => ({
          ...props,
          margin: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        placeholder: (props) => ({
          ...props,
          color: "#979797",
          marginLeft: 0,
          marginRight: 0,
        }),
        dropdownIndicator: (props) => ({
          ...props,
          padding: 0,
        }),
        valueContainer: (props) => ({
          ...props,
          padding: "0 5px 0 0",
        }),
        option: (props, { isSelected }) => ({
          ...props,
          backgroundColor: isSelected ? "#0DAD8E" : "white",
          "&:active": {
            backgroundColor: "rgba(13, 173, 142, 0.5)",
          },
          "@media(max-width: 576px)": {
            fontSize: "14px",
          },
        }),
      }}
    />
  )
}

import { Header } from "@components/header"
import {
  HomepageBanner,
  JoinUs,
  HomepageQuotes,
  HomepageSearch,
  RequirementStep,
  CoreValues,
  CareerTestimonial,
  CareerBenefit,
  CategoryColumn,
  HomepageFaq,
} from "./components"
import { Helmet } from "@helpers/Helmet"
import { HariseninFooter } from "@components/harisenin"
import { useScreenSize } from "@lib/hooks"

export default function Homepage() {
  const { deviceType } = useScreenSize()

  const executeScroll = (id: string) => () => {
    const ele = document.getElementById(id)

    if (!ele) {
      return
    }

    ele.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <>
      <Helmet isIndexed />
      <Header />

      <main>
        <HomepageBanner scroll={executeScroll} />
        <HomepageSearch />
        <JoinUs deviceType={deviceType} />
        <CoreValues />
        <HomepageQuotes />
        <RequirementStep deviceType={deviceType} />
        <CareerTestimonial deviceType={deviceType} />
        <CareerBenefit />
        <CategoryColumn deviceType={deviceType} />
        <HomepageFaq />
      </main>

      <HariseninFooter />
    </>
  )
}

import React, { FC } from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { ButtonGroupProps } from "react-multi-carousel"
import clsx from "clsx"

interface ArrowProps {
  className?: string
  onClick?: () => void
  iconSize?: string
}

interface ArrowRowProp extends ButtonGroupProps {
  length: number
  size?: "md" | "lg"
  displayedSlide: number
  className?: string
  leftClassName?: string
  rightClassName?: string
  iconSize?: string
}

export function PrevArrow({ onClick, className, iconSize }: ArrowProps) {
  return (
    <button onClick={onClick} className={clsx("left-[-10px]", className)}>
      <FaChevronLeft className={clsx("text-[18px]", iconSize ?? "text-[18px]")} />
    </button>
  )
}

export function NextArrow({ onClick, className, iconSize }: ArrowProps) {
  return (
    <button onClick={onClick} className={clsx("right-[-10px]", className)}>
      <FaChevronRight className={clsx("text-[18px]", iconSize ?? "text-[18px]")} />{" "}
    </button>
  )
}

export const SliderArrow: FC<ArrowRowProp> = ({
  next,
  previous,
  length,
  displayedSlide,
  size,
  className,
  leftClassName,
  rightClassName,
  iconSize,
  ...props
}) => {
  const current = props.carouselState?.currentSlide

  const SLIDER_ARROW =
    "absolute top-[42%] flex items-center justify-center bg-primary-grey rounded-full shadow-[0_2px_5px_-0px_rgba(0,0,0,0.3)] absolute text-green slider-button-shadow"

  return (
    <div className={clsx(`z-10 flex w-full`, className)}>
      <PrevArrow
        className={clsx(
          (current === 0 || length < displayedSlide) && "hidden",
          // size === "lg" ? "w-[50px] h-[50px]" : 'w-[40px] h-[40px]',
          "w-[50px] h-[50px]",
          SLIDER_ARROW,
          leftClassName
        )}
        iconSize={iconSize}
        onClick={previous}
      />
      <NextArrow
        onClick={next}
        iconSize={iconSize}
        className={clsx(
          (length - displayedSlide === current || length < displayedSlide) && "hidden",
          // size === "lg" ? "w-[50px] h-[50px]" : "w-[40px] h-[40px]",
          "w-[50px] h-[50px]",
          SLIDER_ARROW,
          rightClassName
        )}
      />
    </div>
  )
}
